import CustomNotification from '../../Components/CustomNotification'
import * as SF from '../ModuleFunctions/SSAS_Functions.js';
import * as SP from '../ComponentData/SSAS_Page.js'
import { formatInTimeZone } from 'date-fns-tz';
import { createRoot } from 'react-dom/client';
import { Button, Form } from 'react-bootstrap';
import { sendDataToParent } from './Iframe_Handler.js';

function getRandomFourDigitNumber() {
    return String(Math.floor(Math.random() * 10000)).padStart(4, '0');
}

export function handleSASSNotificationsList(params_list){
    let notificationTitle = [];
    let notificationContent = [];
    let notificationClass = [];
    let idList = [];
    let itemIdList = [];
    let i = 0;

    params_list.forEach((notif) => {
        let thisTitle;
        let thisContent;
        let typeClass;
        idList.push("SASSAlertNot"+getRandomFourDigitNumber()+"-"+i);
        if(notif[0].toUpperCase() == "TEST") {
            thisTitle = "TEST MODE - SSAS FIRING FOR VESSEL: " + notif[2];
            thisContent = SASSTestModeAlert(notif[2], notif[3], notif[1]);
            typeClass = "notification_SSAS_test";
        }
        else if(notif[0].toUpperCase() == "ALERT"){
            thisTitle = "SSAS FIRING FOR VESSEL: " + notif[2];
            thisContent = SASSActiveAlert(notif[2], notif[3], notif[1]);
            typeClass = "notification_SSAS_alert";
        }
        notificationTitle.push(thisTitle);
        notificationContent.push(thisContent);
        notificationClass.push(typeClass);
        itemIdList.push(notif[1]);
        i++;
    });

    let notificationContainer = document.createElement('div');
    notificationContainer.id = "SASSAlertNot";

    const root = createRoot(
        notificationContainer
    );

    root.render(
        <CustomNotification breadcrumbs="SASSNotifications" title={notificationTitle} text_content={notificationContent}
                custom_buttons={null} includeClose={[true, "Close popup"]} notification_class={notificationClass} call_back_func={null}
                on_close={SASSNotificationClose} itemId={itemIdList} />
    );
}

function SASSTestModeAlert(vessel, date, vessel_uuid){
    const formattedDate = formatInTimeZone(new Date(date), "UTC", "MMMM do yyyy, HH:mm 'UTC'");

    const addContent = (
        <p className="text">
            TEST MODE -- TEST MODE -- TEST MODE <br /><br />
            {formattedDate}<br /><br />
            CLICK <a href="#" onClick={(e) => { e.preventDefault(); SASSNotificationOnclick(vessel_uuid); }}>
                HERE
            </a> TO OPEN LATEST SSAS MESSAGE, LOCATE VESSEL, AND ACKNOWLEDGE THIS MESSAGE.<br /><br />
            TEST MODE -- TEST MODE -- TEST MODE
        </p>
    );
    return addContent;
}
function SASSActiveAlert(vessel, date, vessel_uuid){
    const formattedDate = formatInTimeZone(new Date(date), "UTC", "MMMM do yyyy, HH:mm 'UTC'");

    const addContent = (
        <p className="text">
            {formattedDate}<br /><br />
            SSAS HAS BEEN ACTIVATED FOR VESSEL: {vessel}<br /><br />
            CLICK <a href="#" onClick={(e) => { e.preventDefault(); SASSNotificationOnclick(vessel_uuid); }}>
                HERE
            </a> TO OPEN LATEST SSAS MESSAGE, LOCATE VESSEL, AND ACKNOWLEDGE THIS MESSAGE.
        </p>
    );
    return addContent;
}
function SASSNotificationClose (close_all: boolean, close_item:string){
    const currentURL = window.location.href;
    let message = { item:"ssasnotification", value:"", value2:close_item}
    if(close_all) message.value = "CLOSE ALL";
    else message.value = "CLOSE";

    if(currentURL.includes("?iframe")) sendDataToParent(message);
}
/*function SASSNotificationCloseAll (close_all: boolean, close_item:string){
    if(close_all){
        const currentURL = window.location.href;
        if(currentURL.includes("?iframe")){
            sendDataToParent({ item:"ssasnotification", value:"CLOSE ALL" });
        }
    }
}*/
function SASSNotificationOnclick (vessel){
    sendDataToParent({ item:"ssasnotification_flyto", value:vessel });
}

export function handleSASSRecordDetails(type, currentAlert, currentContact){
    let thisTitle;
    let thisContent;
    let thisButton;
    let thisClass;

    if(type.toUpperCase() == "TEST"){
        thisTitle = "Record Test Details";
        thisClass = "record_SSAS_test";
        [thisContent, thisButton] = SASSRecordTest(currentAlert, currentContact);
    }if(type.toUpperCase() == "LIVE"){
        thisTitle = "Record Alert Details";
        thisClass = "record_SSAS_alert";
        [thisContent, thisButton] = SASSRecordAlert(currentAlert, currentContact);
    }

    let notificationContainer = document.createElement('div');
    notificationContainer.id = "SASSAlertNot";

    const root = createRoot(notificationContainer);

    root.render(
        <CustomNotification breadcrumbs="SASSNotifications" title={[thisTitle]} text_content={[thisContent]}
                custom_buttons={[thisButton]} includeClose={[true, "Cancel"]} notification_class={[thisClass]} call_back_func={null}
                on_close={SASSNotificationClose} />
    );
}
function SASSRecordAlert (currentAlert, currentContact){
    const record_content = (
        <>
            <p className="text">Please record any alert notes here</p>

            <Form.Control as="textarea" placeholder="Enter notes here"/>
        </>
    );
    const record_button = (<>
            <Button onClick={(e) => SF.ssasAlertPostRecord(currentAlert, currentContact, e)} variant="success">Save</Button>
        </>);
    return [record_content, record_button];
}
function SASSRecordTest (currentAlert, currentContact){
    const record_content = (
        <>
            <p className="text">Please record any notes regarding the test here, then select one of the buttons below</p>

            <Form.Control as="textarea" placeholder="Enter notes here"/>
        </>
    );
    const record_button = (
        <>
            <Button onClick={(e) => SF.ssasScheduledTestPostRecord(currentAlert, SP.scheduledTestStatuses.get(0)?.[0], currentContact, e)} variant="secondary">{SP.scheduledTestStatuses.get(0)?.[0]}</Button>
            <Button onClick={(e) => SF.ssasScheduledTestPostRecord(currentAlert, SP.scheduledTestStatuses.get(3)?.[0], currentContact, e)} variant="danger">{SP.scheduledTestStatuses.get(3)?.[0]}</Button>
            <Button onClick={(e) => SF.ssasScheduledTestPostRecord(currentAlert, SP.scheduledTestStatuses.get(4)?.[0], currentContact, e)} variant="success">{SP.scheduledTestStatuses.get(4)?.[0]}</Button>
        </>
    );
    return [record_content, record_button];
}

export function handleNotificationRender(containerId, breadcrumb, titleList, contentList, customButtonList, includeClose, classList, onClose = null, callBack = null){
    let notificationContainer = document.createElement('div');
    notificationContainer.id = containerId;

    const root = createRoot(
        notificationContainer
    );

    root.render(
        <CustomNotification breadcrumbs={breadcrumb} title={titleList} text_content={contentList}
                custom_buttons={customButtonList} includeClose={[true, "Cancel"]} notification_class={classList} call_back_func={callBack}
                on_close={onClose} />
    );
};

export function basicNotification(message, messageTitle, isClose = true, className = "",  callbackButtons = null, id = "basicNotification"){
    const title = (<> <p className="title">{messageTitle}</p> </>);
    const content = (<> <p className="text">{message}</p> </>);
    let buttons = (<></>);
    let closeText = "Cancel";
    if(isClose) closeText = "Close";

    //console.log("callbackButtons: ", callbackButtons);
    if(callbackButtons) buttons = callbackButtons;
    //console.log("buttons: ", buttons);

    let notificationContainer = document.createElement('div');
    notificationContainer.id = "basicNotification";
    if(className != "") notificationContainer.classList.add(className);

    const root = createRoot( notificationContainer );

    root.render(
        <CustomNotification breadcrumbs="basicNotification" title={[title]} text_content={[content]} custom_buttons={buttons}
         includeClose={[true, closeText]} notification_class={["basic_MT_notif"]} call_back_func={null} on_close={SASSNotificationClose} />
    );
}

export function externalCloseNotification(){
    const notifDiv = document.getElementById("popup-root");
    notifDiv.remove();
}

/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
<FontAwesomeIcon icon={['fas', 'crosshairs']} />*/
export async function SSASCornerPopup(parameters){
    let liveAlertList = await SP.getAlertsData(true, [], parameters);
    let testAlertList = await SP.getAlertsData(false, [], parameters);
    let vesselsInTestMode = await SP.getSSASVesselData([],[], SP.alertStatusElement.get(4)[0], false, parameters);

    const cornerContent = <div id="SSASInfoCorner">
        {liveAlertList.map(alert => (
            <div class="row liveAlert">
                <p>{alert.vessel_info.vessel_name}</p><Button onClick={SASSNotificationOnclick(alert.vessel_info)}>FLY</Button>
            </div>
        ))}
        {testAlertList.map(alert => (
            <div class="row testAlert">
                <p>{alert.vessel_info.vessel_name}</p><Button onClick={SASSNotificationOnclick(alert.vessel_info)}>FLY</Button>
            </div>
        ))}
        {vesselsInTestMode.map(vessel => (
            <div class="row testMode">
                <p>{vessel.vessel_name}</p><Button onClick={SASSNotificationOnclick(alert.vessel_info)}>FLY</Button>
            </div>
        ))}
        </div>
    cornerPopup("", "SSASCornerPopup", cornerContent);
}
export function cornerPopup(title, divID, content){
    let notificationContainer = document.createElement('div');
    notificationContainer.id = divID;
    document.body.appendChild(notificationContainer);


    const root = createRoot( notificationContainer );

    root.render(<div id="popup-root">
            <CustomNotification breadcrumbs="cornerPopup" title={[title]} text_content={[content]} custom_buttons={null}
         includeClose={[false, ""]} notification_class={["cornerPopup"]} call_back_func={null} on_close={null} />
         </div>

    );
}