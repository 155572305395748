import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import * as SP from '../Javascript/ComponentData/SSAS_Page.js';
import * as SF from '../Javascript/ModuleFunctions/SSAS_Functions.js';
import { session_user_uuid, session_parent_uuid } from '../Javascript/session.js';
import * as G from '../Javascript/Other/General.js';
import { Container, Row, Col, Tabs, Tab, Button, Form, InputGroup } from 'react-bootstrap';
import CustomTable from '../Components/CustomTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { matchSorter } from 'match-sorter';
import '../CSS/style.css';
import '../CSS/Module_CS/ssas_page.css';
import { v4 as uuidv4 } from 'uuid';
import '../CSS/Module_CS/components.css';
import WSMessageHandler from '../Components/WSMessageHandler';

interface Props {
  breadcrumbs: string;
}

const SSASPage: React.FC<Props> = ({ breadcrumbs }) => {
    const messages = WSMessageHandler.useMessages([{ topic: session_parent_uuid, subtopic: "SSAS_CONTACT", user_id: session_user_uuid, source_api: "", data: {} },
        { topic: session_parent_uuid, subtopic: "SSAS_SCHEDULE", user_id: session_user_uuid, source_api: "", data: {} },
        { topic: session_parent_uuid, subtopic: "SSAS_EVENT", user_id: session_user_uuid, source_api: "", data: {} },
        { topic: session_parent_uuid, subtopic: "SSAS_ALERT", user_id: session_user_uuid, source_api: "", data: {} },
        { topic: session_parent_uuid, subtopic: "SSAS_VESSEL", user_id: session_user_uuid, source_api: "", data: {} },
        { topic: session_parent_uuid, subtopic: "SSAS_AFFILIATION", user_id: session_user_uuid, source_api: "", data: {} }]);
    let messagesHistory = useRef<any[]>([]);
    const [localMessage, setLocalMessage] = useState<string | null>(null);

    useEffect(() => {
        if (!messages) return;

        const processMessages = async () => {
            for (const message of messages) {
                switch (message.subtopic) {
                    case "SSAS_CONTACT":
                        const res = await SF.processSSAS_CONTACTMessage(message, contacts, userContact)
                        if(res[0] != null) setContacts(res[0]);
                        if(res[1] != null) setUserContact(res[1]);
                        break;
                    case "SSAS_SCHEDULE":
                        const resTests = await SF.processSSAS_SCHEDULEMessage(message, testSchedule, contacts, allSSASVessels);
                        if(resTests[0] != null) setTestSchedule(resTests[0]);
                        if(resTests[1] != null) setAllSSASVessels(await SP.getSSASVessel_nextScheduledTests(resTests[1], resTests[0]));
                        else setAllSSASVessels(await SP.getSSASVessel_nextScheduledTests(allSSASVessels, resTests[0]));
                        break;
                    case "SSAS_EVENT":
                        setEvents(await SF.processSSAS_EVENTMessage(message, events));
                        break;
                    case "SSAS_ALERT":
                        const res2 = await SF.processSSAS_VESSELMessage(message, allSSASVessels, true);
                        if(res2 != null) setAllSSASVessels(await SP.getSSASVessel_nextScheduledTests(res2, testSchedule ? testSchedule : null));
                        break;
                    case "SSAS_VESSEL":
                        const res3 = await SF.processSSAS_VESSELMessage(message, allSSASVessels);
                        console.log("res3: ", res3);
                        if(res3 != null) setAllSSASVessels(await SP.getSSASVessel_nextScheduledTests(res3, testSchedule ? testSchedule : null));
                        break;
                    default:
                        break;
                }
            }
        };

        processMessages();
    }, [messages]);

    const [userUuid, setUserUuid] = useState<string | null>(G.getUserUuid().toString());
    const [is_loading, set_is_loading] = useState(false);
    const [triggerReRender, setTriggerReRender] = useState(false);
    const [ssasContent, setSsasContent] = useState<SP.ssasContent>(SP.defaultSsasContent);
    const [dashboardDate, setDashboardDate] = useState<string>(G.endStringByLastOfChar(new Date().toISOString(), 'T'))
    const [userContact, setUserContact] = useState<SP.SSASContact>(SP.defaultSsasContact);

    const updateDashboardDate = (event: React.MouseEvent) =>{
        SF.dashboardSSASScheduleTest(event, "scheduleTestPopup", onchangeDashboardDate);
    }
    const onchangeDashboardDate = (event: React.MouseEvent, returnDate:string) =>{
        if(returnDate != null && returnDate != "") setDashboardDate(G.endStringByLastOfChar(returnDate, ' '));
    }

    const addNewContact = (external: boolean) =>{
        if(external){
            const emptyContact: SP.SSASContact = structuredClone(SP.defaultSsasContact);
            emptyContact.contact_uuid = G.getEmptyUUID();
            emptyContact.internal_bool = false;
            setContacts((prevData: SP.SSASContact[] | null) => {
                return prevData ? [...prevData, emptyContact] : [emptyContact];
            });
        }
        else{
            SF.addContactNotif(contacts, addedContacts);
        }
    }

    const addedContacts = async (newContact: SP.SSASContact) =>{
        /*setContacts((prevData: SP.SSASContact[] | null) => { return prevData ? [...prevData, newContact] : [newContact]; });
        refreshEventLogs();*/
    }
    const refreshEventLogs = async () =>{
        const res = await SP.getEventData([], [], [], [], [], [], false);
        if (JSON.stringify(res) !== JSON.stringify(events)) setEvents(res);
        //if(res.length > 0) setEvents(res);
    }

    const [expandVessel, setExpandVessel] = useState<SP.SSASVessel[] | null>(null);
    const [contactsExpandColumns, setContactsExpandColumns] = useState<ColumnDef<SP.SSASVessel>[] | null>(null);
    const toggleExpandContact = (row_id: string) => {
      if (contacts != null) {
        setContacts(prevData => (prevData ?? []).map(test => test.contact_uuid === row_id ? { ...test, expand: !test.expand } : test) );
      }
    };
    const handleRowDelete = (row: SP.SSASContact) => {
       setContacts((prevContacts) => {
         if (!prevContacts) return null;
         return prevContacts.filter((contact) => contact.contact_uuid !== row.contact_uuid);
       });
     };
    const [contacts, setContacts] = useState<SP.SSASContact[] | null>(null);
    const [contactsColumns, setContactsColumns] = useState<ColumnDef<SP.SSASContact>[]>(SF.getContactsTableData({}, { toggleExpandContact, handleRowDelete }, ssasContent.ssas_contacts_tab_table_column, true, userContact) as ColumnDef<SP.SSASContact>[]);
    const [affiliationList, setAffiliationList] = useState<SP.SSASAffiliation[] | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            if(contacts != null && contacts.length > 0) {
                let contact_ids:string[] = contacts.map(contact => contact.contact_uuid);
                const res = await SP.getAffiliationData([],contact_ids,[]);
                if (JSON.stringify(res) !== JSON.stringify(affiliationList)) setAffiliationList(res);
            }
        }
        fetchData();
    },[contacts]);
    useEffect(() => {
        const fetchData = async () => {
            if(contacts === null || triggerReRender){
                const res = await SP.getContactData([],[]);
                if (JSON.stringify(res) !== JSON.stringify(contacts)) setContacts(res);
            }
        }
       fetchData();
    }, [triggerReRender]);
    useEffect(() => {
        const fetchData = async () => {
           if(userContact == null || userContact.contact_uuid === null || userContact.contact_uuid === ""){
             const res = await SP.getContactData([],[], true);
             if (JSON.stringify(res) !== JSON.stringify(userContact)) setUserContact(res[0]);
           }
       }
       fetchData();
    },[userContact]);
    useEffect(() => {
        setContactsColumns(SF.getContactsTableData({}, { toggleExpandContact, handleRowDelete }, ssasContent.ssas_contacts_tab_table_column, true, userContact) as ColumnDef<SP.SSASContact>[]);
    },[userContact, contacts]);

    const [activeAlerts, setActiveAlerts] = useState<SP.SSASAlert[] | null>(null);
    const [activeAlertsColumns, setActiveAlertsColumns] = useState<ColumnDef<SP.SSASAlert>[]>(SF.getAlertTableData({}, ssasContent.ssas_dashboard_tab_table1_column) as ColumnDef<SP.SSASAlert>[]);

    const toggleExpandTestSchedule = (row_id: string) => {
      if(testSchedule != null){
          setTestSchedule(prevData =>
            (prevData ?? []).map(test =>
              test.schedule_uuid === row_id ? { ...test, expand: !test.expand } : test
            )
          );
      }
    };
    const handleTestRowDelete = (row: SP.SSASScheduledTests) => {
       setTestSchedule(prevData =>{
         if (!prevData) return null;
         return prevData.filter((test) => test.schedule_uuid !== row.schedule_uuid);
       });
    };

    const [schedule, setSchedule] = useState<SP.SSASScheduledTests[] | null>(null);
    const [scheduleColumns, setScheduleColumns] = useState<ColumnDef<SP.SSASScheduledTests>[]>(SF.getScheduleTableData({}, true, {}, userContact, ssasContent.ssas_dashboard_tab_table2_column) as ColumnDef<SP.SSASScheduledTests>[]);
    const [testSchedule, setTestSchedule] = useState<SP.SSASScheduledTests[] | null>(null);
    const [testScheduleColumns, setTestScheduleColumns] = useState<ColumnDef<SP.SSASScheduledTests>[]>(SF.getScheduleTableData({}, false, { toggleExpandTestSchedule, handleTestRowDelete }, userContact, ssasContent.ssas_schedule_tab_table_column) as ColumnDef<SP.SSASScheduledTests>[]);
    useEffect(() => {
        setTestScheduleColumns(SF.getScheduleTableData({}, false, { toggleExpandTestSchedule, handleTestRowDelete }, userContact, ssasContent.ssas_vessel_tab_table_column) as ColumnDef<SP.SSASScheduledTests>[]);
    },[testSchedule]);

    const [allSSASVessels, setAllSSASVessels] = useState<SP.SSASVessel[] | null>(null);
    const [deletedSSASVessels, setDeletedSSASVessels] = useState<SP.SSASVessel[] | null>(null);
    const [ssasVesselsColumns, setSSASVesselsColumns] = useState<ColumnDef<SP.SSASVessel>[]>(SF.getVesselsTableData({}, false, '', ssasContent.ssas_vessel_tab_table_column) as ColumnDef<SP.SSASVessel>[]);
    const [events, setEvents] = useState<SP.SSASEvent[] | null>(null);
    const [eventsColumns, setEventsColumns] = useState<ColumnDef<SP.SSASEvent>[]>(SF.getEventsLogsTableData({}, ssasContent.ssas_event_tab_table_column) as ColumnDef<SP.SSASEvent>[]);
    useEffect(() => {
        setEventsColumns(SF.getEventsLogsTableData({}, ssasContent.ssas_event_tab_table_column) as ColumnDef<SP.SSASEvent>[]);
    },[events]);

    const [i, setI] = useState<number>(0);
    useEffect(() => {
        const fetchData = async () => {
            if(ssasContent.ssas_dashboard_tab_title === ""){
               const res = await SP.fetchSSASContent();
               if (JSON.stringify(res) !== JSON.stringify(ssasContent)) setSsasContent(res);
            }
        }
        fetchData();
    }, [triggerReRender]);
    useEffect(() => {
        const fetchData = async () => {
             if(activeAlerts === null || triggerReRender){
                const [res, res_test] = await Promise.all([SP.getAlertsData(), SP.getAlertsData(true)]);

                const newAlerts = [...res, ...res_test];
                if (JSON.stringify(newAlerts) !== JSON.stringify(activeAlerts)) setActiveAlerts(newAlerts);
                /*if(res.length > 0 && res_test.length > 0) setActiveAlerts([...res, ...res_test]);
                else if(res_test.length > 0) setActiveAlerts([...res, ...res_test]);
                else if(res.length > 0) setActiveAlerts([...res, ...res_test]);*/
             }
        }
        fetchData();
    }, [triggerReRender]);
    useEffect(() => {
        const fetchData = async () => {
           try {
             if(allSSASVessels === null || triggerReRender){
                const res = await SP.getSSASVesselData([], [], "", true);
                if(res.length > 0) {
                    const activeVessels = res.filter(i => i.deleted == null);
                    const deletedVessels = res.filter(i => i.deleted != null);
                    if(JSON.stringify(deletedVessels) !== JSON.stringify(deletedSSASVessels)) setDeletedSSASVessels(deletedVessels);
                    if(JSON.stringify(activeVessels) !== JSON.stringify(allSSASVessels)) setAllSSASVessels(await SP.getSSASVessel_nextScheduledTests(activeVessels));
                }
             }
             if(events === null || triggerReRender) refreshEventLogs();
             if(session_user_uuid === "") G.getUserUuid();
           } catch (error) { console.error(error); }
           finally { set_is_loading(false);}
         };
       fetchData();
    },[allSSASVessels, session_user_uuid, session_parent_uuid, triggerReRender]);

    useEffect(()=>{
        const fetchData = async () => {
            const restest = await SP.getSSASScheduledTests([], [], "", true, false, false);
            if(restest.length > 0) {
                const result = await SF.getTestVesselInfo(restest, allSSASVessels, deletedSSASVessels);
                if(JSON.stringify(result) !== JSON.stringify(testSchedule)) setTestSchedule(result);
            }
            const res = await SP.getSSASScheduledTests([], [], "", true, false, false, dashboardDate);
            if(res.length > 0) {
                const result = await SF.getTestVesselInfo(res, allSSASVessels, deletedSSASVessels);
                if(JSON.stringify(result) !== JSON.stringify(schedule)) setSchedule(await SF.getTestVesselInfo(res, allSSASVessels, deletedSSASVessels));
            }
        }
        if(allSSASVessels != null){
            fetchData();
        }
    }, [allSSASVessels, dashboardDate]);
    useEffect(()=>{
        if(affiliationList != null && contacts != null) {
            contacts.map(x => {
                const foundRelation = affiliationList?.filter(j => j.contact_uuid === x.contact_uuid);
                if (foundRelation) x.relation = foundRelation;
            });
        }
    }, [affiliationList]);

    const expandElementContact = useCallback((row_id: string, more_info: any) => {
        if(more_info.expand){
            let related_vessels: SP.SSASVessel[] = [];
            let ssasvesselIds:string[] = [];
            if(more_info.relation != null){
                ssasvesselIds = more_info.relation.map((i: SP.SSASAffiliation) => i.ssas_vessel_uuid);
            }
            if(allSSASVessels != null){
                related_vessels = allSSASVessels.filter(i => ssasvesselIds.includes(i.ssas_vessel_uuid));
            }
            return (<tr><td></td><td className="ssas_contact_expand_cont" colSpan={6}><CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|ContactExpandTable"} columns={ssasVesselsColumns} data={related_vessels}
            rows_displayed={related_vessels ? related_vessels.length : 0} dropdown_placeholder={"All"} expand_element={null} export_callback={SP.exportSSASTable}/></td></tr>);
        }
    }, [allSSASVessels]);

    const expandElementTestSchedule = useCallback((row_id: string, more_info: any) => {
        let testLogs: SP.SSASEvent[] = [];
        if(events != null && events.length > 0){
            const res = events.filter(L => L.test_details && L.test_details.schedule_uuid == more_info.schedule_uuid);
            if(res && res.length > 0) {
                testLogs = res;
                const res1 = testLogs.filter(L => L.test_details && L.log_type.toLowerCase() == SP.ssasLogTypes.get(11)?.[1].toLowerCase() &&
                    L.test_details.completed_date == more_info.completed_date);
                if(res1 && res1.length > 0) more_info.notes = res1[0].log_details;
            }
        }
        if(more_info.expand){
            let hasTestUnderway: SP.SSASScheduledTests[] = (testSchedule ?? []).filter(i =>
                i.ssas_vessel_uuid === more_info.ssas_vessel_uuid && i.current_status === SP.scheduledTestStatuses.get(2)?.[0]);
            return SF.expandScheduledTestContent(more_info, userContact, eventsColumns, breadcrumbs, testLogs,
                hasTestUnderway?.length > 0);
        }
        else {
            return (null);
        }
    }, [userContact, events]);

    return (
      <Container id="ssas_page">
        <div className="header">
            <h1>SSAS</h1>
        </div>
        <div id="scheduleTestPopup"></div>
        {is_loading ? (
            G.showLoadingPanel()
        ) : (
            <Tabs defaultActiveKey="dashboard" id="ssas_tab_cont">
                <Tab eventKey="dashboard" title={ssasContent.ssas_dashboard_tab_title}>
                    <Container id="ssas_tab_cont-dashboard">
                        <Row>
                          <Col>
                            <h2 id="ssasAlertHeader">{ssasContent.ssas_dashboard_tab_table1_title}</h2>
                            <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_dashboard_tab_table1_title} columns={activeAlertsColumns} data={activeAlerts}
                                rows_displayed={activeAlerts ? activeAlerts.length : 0} dropdown_placeholder={"All"} expand_element={null} export_callback={SP.exportSSASTable}/>
                          </Col>
                          <Col>
                            <h2>{ssasContent.ssas_dashboard_tab_table2_title}</h2>

                            <Form.Group className="mb-3" controlId="dashboard.scheduleDate">
                                <Form.Control type="text" onClick={(e) => updateDashboardDate(e)} value={dashboardDate.replace("T", " ")} readOnly/>
                            </Form.Group>
                            <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_dashboard_tab_table2_title} columns={scheduleColumns} data={schedule}
                                rows_displayed={schedule ? schedule.length : 0} dropdown_placeholder={"All"} expand_element={null} export_callback={SP.exportSSASTable}/>
                          </Col>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="test_schedule" title={ssasContent.ssas_schedule_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_schedule_tab_title}</h2>
                            <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_schedule_tab_title} columns={testScheduleColumns} data={testSchedule}
                            rows_displayed={testSchedule ? testSchedule.length : 0} dropdown_placeholder={"All"} expand_element={expandElementTestSchedule} export_callback={SP.exportSSASTable}/>
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab className="contacts_cont" eventKey="contacts" title={ssasContent.ssas_contacts_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_contacts_tab_title}</h2>
                            <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|ContactsTable"} columns={contactsColumns} data={contacts}
                            rows_displayed={contacts ? contacts.length : 0} dropdown_placeholder={"All"} expand_element={expandElementContact} export_callback={SP.exportSSASTable}/>
                          </div>
                        </Row>
                        { userContact?.master ? (
                        <div className="contact_cont btn_cont">
                            <Button variant="info" onClick={() => addNewContact(false)}>Add Contact</Button>
                            <Button variant="info" onClick={() => addNewContact(true)}>Add External Contact</Button>
                        </div>) : null }
                    </Container>
                </Tab>
                <Tab eventKey="event_logs" title={ssasContent.ssas_event_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_event_tab_title}</h2>
                            <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|EventLogsTable"} columns={eventsColumns} data={events}
                                rows_displayed={20} dropdown_placeholder={"All"} expand_element={null} export_callback={SP.exportSSASTable}/>
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="vessels" title={ssasContent.ssas_vessel_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_vessel_tab_title}</h2>
                            <CustomTable breadcrumbs={`${breadcrumbs}|SSAS_Page|VesselsTable`} columns={ssasVesselsColumns} data={allSSASVessels}
                            rows_displayed={allSSASVessels ? allSSASVessels.length : 0} dropdown_placeholder={"All"} expand_element={null} export_callback={SP.exportSSASTable}/>
                          </div>
                        </Row>
                    </Container>
                </Tab>
            </Tabs>
        )}
      </Container>
    );
};

export default SSASPage;