import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getSessionUserUuid, getSessionParentUuid } from '../Javascript/session.js';
import DetailsPage from '../Pages/Details_Page';
import SSASPage from '../Pages/SSAS_Page';
import * as IF from '../Javascript/ModuleFunctions/Iframe_Handler.js';
import * as N from '../Javascript/ModuleFunctions/Notifications.js';
import * as SP from '../Javascript/ComponentData/SSAS_Page.js';
import * as G from '../Javascript/Other/General.js';
import '../CSS/style.css';

interface Props {
   breadcrumbs: string;
   notification_type: string;
   parameters: string | null;
}
const IframeNotification: React.FC<Props> = ({ breadcrumbs, notification_type, parameters }) => {
    const [ssasNotificationParameters, setSsasNotificationParameters] = useState<[string,string,string, Date] | null>(null);
    useEffect(() =>{
        const fetchData = async () => {
            console.log("notification_type: ", notification_type);
            console.log("parameters: ", parameters);
            if(notification_type === "SSASCORNER"){
                N.SSASCornerPopup(parameters);
            }
            else if(notification_type === "SSASALERT"){
                if(parameters){
                    const alert_list = JSON.parse(parameters);
                    N.handleSASSNotificationsList(alert_list);
                } else N.basicNotification("An error occurred", "An unknown error occurred.", true, "smallNotification");
            }
            else if(notification_type === "SSASRECORD"){
                if(parameters){
                    const record_details = JSON.parse(parameters);
                    let res_alert = null;

                    if(record_details.type === "TEST" && record_details.alert_uuid) {
                        res_alert = await SP.getAlertsData(true, [record_details.alert_uuid]);
                    }
                    else if(record_details.alert_uuid) res_alert = await SP.getAlertsData(false, [record_details.alert_uuid])
                    const alert_details:SP.SSASAlert | null = res_alert ? res_alert[0] : null;

                    const res_contact = await SP.getContactData([record_details.contact_uuid], [], false);
                    const contact_details:SP.SSASContact = res_contact[0];

                    N.handleSASSRecordDetails(record_details.type,  alert_details, contact_details);
                } else N.basicNotification("An error occurred", "An unknown error occurred.", true, "smallNotification");
            }
            else{
                N.basicNotification("An error occurred", "An unknown error occurred.", true, "smallNotification");
            }
        }
        fetchData();
    },[notification_type, parameters]);
    return (
        <div>
        </div>
    );

};

export default IframeNotification