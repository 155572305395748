import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSessionUserUuid, getSessionParentUuid } from './Javascript/session.js';
import DetailsPage from './Pages/Details_Page';
import SSASPage from './Pages/SSAS_Page';
import IframeNotification from './Components/IframeNotification';
import * as ih from './Javascript/ModuleFunctions/Iframe_Handler.js';
import * as gen from './Javascript/Other/General.js';
import './CSS/style.css';

interface Props {
}
const IframeHandler: React.FC<Props> = ({}) => {
    const [component, setComponent] = useState<string>("");
    const [parameters, setParameters] = useState<string[]>([]);
    const [is_loading, set_is_loading] = useState(true);
    const [userUuid, setUserUuid] = useState<string>("");
    const [parentUuid, setParentUuid] = useState<string>("");
    const sendDataToParent = () => {
        window.parent.postMessage({ key: 'value' }, '*');
      };
    useEffect(() => {
       const fetchData = async () => {
           try {
             if(ih.component){
                setComponent(ih.component);
             }
             if(ih.parameters){
                setParameters(ih.parameters.split('|'));
             }
           } catch (error) {
             console.error(error);
           } finally {
             if((ih.component === "details" && ih.parameters != null && ih.parameters.includes('mobile')) || (userUuid != "" && parentUuid != "")){
                set_is_loading(false);
             }
           }
         };

       const checkSessionValues = () => {
           const newUserUUID = getSessionUserUuid();
           const newParentUUID = getSessionParentUuid();

           if (newUserUUID !== userUuid) {
               setUserUuid(newUserUUID);
           }

           if (newParentUUID !== parentUuid) {
               setParentUuid(newParentUUID);
           }

           // Stop the interval if both UUIDs are populated
           if (newUserUUID && newParentUUID) {
               set_is_loading(false); // Ensure loading state is updated
               clearInterval(interval);
           }
           attemptCount += 1;
           if (attemptCount >= 30) {
               console.warn("Max attempts reached, stopping interval.");
               set_is_loading(false);
               clearInterval(interval);
           }
       };

       gen.getUserUuid();
       fetchData();
       let interval: NodeJS.Timeout;
       let attemptCount = 0;
       if (userUuid === "" && parentUuid === "") {
           interval = setInterval(checkSessionValues, 2000);
       }

       return () => {
           if (interval) {
               clearInterval(interval);
           }
       };
    }, [ih.component, ih.parameters, userUuid, parentUuid]);

    return (
    <>
        {is_loading ? (
            gen.showLoadingPanel()
        ) : (
            <>
            {component === "details" ? (
                <div>
                    <DetailsPage breadcrumbs={"IframeHandler"} detailsContentType={parameters[0]} contentId={parameters[1]} />
                </div>
            ) : component === "SSAS" ? (
                <div>
                    <SSASPage breadcrumbs={"IframeHandler"} />
                </div>
            ) : component === "SSASALERT" || component === "SSASRECORD" || component === "SSASCORNER" ? (
                 <div>
                     <IframeNotification breadcrumbs={"IframeHandler"}  notification_type={component}  parameters={ih.parameters} />
                 </div>
            ) : null}
            </>
        )}
        <style>
        {`
            .modal-header{
                display:none;
            }
        `}
        </style>
    </>
    );
};

export default IframeHandler;

/*
const context = useContext(WebSocketContext);
    if (!context) throw new Error('WebSocket context is not available!');

    const { message, wsService, subscribeToTopic } = context;

    useEffect(() => {
        if (wsService.connectionState === 'open') {
          console.log('Subscribing to topic...');
          subscribeToTopic('SSAS_TEST', {});
        }
      }, [wsService.connectionState, subscribeToTopic]);

    useEffect(() => {
        if (message) {
          console.log('Received WebSocket Message:', message);
        }
    }, [message]);
*/